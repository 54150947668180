.fieldApp main {
  @apply p-4;
}

.fieldApp main h1 {
  @apply text-4xl font-bold mb-2;
}

.fieldApp main h2 {
  @apply my-4 text-xl font-semibold;
}

.fieldApp .buttonGrid {
  @apply flex flex-wrap gap-[35px] mt-4 mb-8;
}

.fieldApp .buttonGrid > * {
  @apply shrink-0;
}

.fieldApp .buttonGrid img {
  @apply w-[100px] h-[88px] object-contain;
}
