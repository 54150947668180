#navbar-switch-campus {
    .basic-panel-card {
        position: absolute;
        bottom: 0.7rem;
        width: 90%;
        background-color: rgba(0, 171, 85, 0.1);
        border-radius: 6px;
        display: flex;
        padding: 8px;
        justify-content: space-around;
        align-items: center;
        height: 3.5rem;
        .basic-panel-card-avatar {
            border-radius: 50%;
            aspect-ratio: 1 / 1;
            width: 2.5rem;
        }
        .basic-panel-menu_text {
            display: flex;
            user-select: none;
            white-space: nowrap;
            font-weight: 700;
            .basic-panel-menu_text-start {
                font-size: 0.875rem;
                font-weight: 700;
                font-style: normal;
                line-height: 1rem;
                letter-spacing: 0em;
                --tw-text-opacity: 1;
                color: rgba(33, 43, 54, var(--tw-text-opacity));
                width: 124px;
                display: block;
                margin-left: 0.5rem;
                margin-right: 0.5rem;
                .basic-panel-menu_text-end {
                    display: flex;
                    align-items: center;
                    font-size: 0.75rem;
                    line-height: 1rem;
                    --tw-text-opacity: 1;
                    color: rgba(99, 115, 129, var(--tw-text-opacity));
                    width: 120px;
                }
            }
            .basic-panel-card-triangle {
                display: flex;
                align-items: center;
                transition-duration: 200ms;
                transition-property: transform;
                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                cursor: pointer;
                width: 20px;
            }
            p {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }

}