.whatsapp-credentials {
  @apply mt-14 mb-8 overflow-x-hidden pr-5 flex flex-col items-center;

  width: 98vw;

  &-form {
    @apply w-full;

    max-width: 800px;
  }

  &-section {
    @apply mb-8;

    &_header {
      @apply mb-5 mt-5;
    }

    &_title {
      @apply font-bold text-base mb-2;

    }

    &_text {
      @apply text-sm;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
    }

    &_content {
      @apply flex flex-wrap gap-y-6 gap-x-7;
    }

    &_footer {
      @apply flex items-center gap-x-5;
    }
  }
}

