* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  font-family: 'Inter', sans-serif;
}

.app {
  @apply flex flex-row;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* -webkit-appearance: none; */
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input {
  outline: none;
}

/* html {
  width: 100vw;
  max-width: 100vw;
} */

#root {
  /* min-height: 98vh; */
  /* height: fit-content; */
  /* height: 100%; */
  max-height: 100vh;
  max-width: 100vw;
  /* overflow-x: hidden; */
}

/* horizontal scroll */
::-webkit-scrollbar:horizontal {
  height: 0.4rem;
}

/* vertical scroll */
::-webkit-scrollbar {
  width: 0.3rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
  border-radius: 0.5rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dadce0;
  border-radius: 0.5rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(141, 139, 139, 0.438);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* -webkit-appearance: none; */
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.navbar-menu-open {
  padding-left: 15rem !important;
}

.navbar-menu-close {
  padding-left: 3.5rem;
}

@media screen and (max-width: 1400px) {
  .navbar-menu-close {
    padding-left: 0;
  }

  .application {
    margin-left: 0 !important;
  }
}

.text-page-name {
  @apply text-2xl;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.toast-message {
  background: darkblue;
  color: #fff;
  font-size: 20px;
  width: 34vw;
  padding: 30px 20px;
}

.text-ellipsis-custom {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.namespace {
  height: 4rem;
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-weight: 800;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.transition-all-300 {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

/* .transition-burger--width { */
/* transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms; */
/* } */

/* @media screen and (max-width: 1599px) {
  .information-field_personal {
    width: 51.588rem;
  }
} */
