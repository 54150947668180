.header {
  animation-fill-mode: both;
  transition: all 0.41s;
  position: absolute;
  top: 0px;
  @apply bg-white flex items-center fixed z-30 border-b border-main-gray;

  &-burger {
    @apply ml-5 h-10 w-10 flex items-center justify-center rounded-full cursor-pointer transition-all;

    &:hover {
      @apply bg-main-gray;
    }
  }
}

.namepage {
  @apply text-black font-extrabold;

  height: 4rem;
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-weight: 800;
}

.header-open {
  @apply ml-60;

  width: calc(100vw - 14.75rem);
}

.header-closed {
  width: calc(100vw - 4.625rem);
  margin-left: 4.625rem;
}

@media screen and (max-width: 1400px) {

  // @media screen and (max-width: 1280px) {
  .header-closed {
    width: calc(100vw + 0.25rem);
    margin-left: 0rem;
  }

  .header-open {
    width: calc(100vw + 14.75rem);
    margin-left: 15rem;
    padding-right: 29rem;
  }
}