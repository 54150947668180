.add-enquiry-upload_image {
  pointer-events: none;
  display: inline-block;
  height: 4rem;
  width: 4rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
}
