.types-of-fees {
  padding: 0 10px;
  // width: 125px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .span-fix::after {
    top: 6px;
  }

  .span-fix::before {
    top: 6px;
  }

  &_title {
    // font-size: 14px;
    font-size: 0.875rem;
    font-weight: 400;
  }

  &_sum {
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 2;
    color: #404eed;
  }
}
