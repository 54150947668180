#small-panel .panel-min-navbar {
  @apply block ml-0 text-main-black;
  /* position: absolute; */
  top: 0px;
  height: 35.1rem;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 0.5rem;

  img {
    @apply mr-2;
  }

  &_link {
    display: flex;
    justify-content: center;
    /* z-index: 10; */
    border-radius: 0.75rem;
    /* text-align: left; */
    font-size: 1rem;
    font-weight: 500;
    font-style: normal;
    /* line-height: 1rem; */
    /* letter-spacing: 0em; */
    --tw-text-opacity: 1;
    color: rgb(99 115 129 / var(--tw-text-opacity));
    transition: 0.2s ease;
    width: 2.75rem;
    height: 2.188rem;
    transition: 0.2s ease;

    &:hover {
      @apply text-main-black bg-main-white;
    }

    &-active {
      @apply text-main-black;
      background: rgba(0, 171, 85, 0.1);
    }
  }
}

@media screen and (min-height: 860px) {
  #small-panel .panel-min-navbar {
    height: 42rem;
  }
}

@media screen and (max-height: 740px) {
  #small-panel .panel-min-navbar {
    height: 32.75rem;
  }
}

@media screen and (max-height: 710px) {
  #small-panel .panel-min-navbar {
    height: 26.75rem;
  }
}

@media screen and (max-height: 660px) {
  #small-panel .panel-min-navbar {
    height: 22.75rem;
  }
}
