.footer {
  @apply fixed bg-white justify-center items-center flex bottom-0;

  height: 4.438rem;
  // width: calc(100% - 20.625rem);
  width: 100%;
  left: 50%;
  transform: translateX(-50%);

  &-row {
    @apply items-center justify-between flex;

    width: 30.063rem;
    height: 3.188rem;

    &-button {
      @apply h-12 w-12 flex flex-col items-center justify-center text-white bg-main-blue;

      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;

      span {
        @apply leading-4 font-medium;
      }
    }
  }
}

.footer-storybook {
  @apply fixed bg-white justify-center items-center flex bottom-0;

  z-index: 3;
  height: 4.438rem;
  width: calc(100% - 13rem);

  &_fullWidth {
    width: 100% !important;
  }

  &-row {
    @apply items-center justify-between flex;

    // width: 30.063rem;
    column-gap: 1rem;
    width: fit-content;
    height: 3.188rem;

    &-button {
      @apply h-12 w-12 flex flex-col items-center justify-center text-white bg-main-blue;

      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;

      span {
        @apply leading-4 font-medium;
      }
    }
  }
}
