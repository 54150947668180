.header-navlink {
  @apply h-16 pr-3 flex items-center bg-white;

  &-link {
    @apply flex mr-4 text-sm font-medium;
    line-height: 1.5rem !important;
    font-size: 1rem;
    font-weight: inherit;
    --tw-text-opacity: 1;
    color: rgba(159, 169, 179, var(--tw-text-opacity));
    // @apply flex mr-4 font-medium;
    // line-height: 22px !important;

    img {
      @apply mr-4;
      padding-top: 2px !important;
    }

    &_active {
      // @apply text-base text-third-gray;
      @apply text-base text-main-black;

      // line-height: 1.5rem !important;
    }
  }
}