.btn {
  @apply px-3 rounded flex items-center;

  img {
    @apply mr-2;
  }

  &-contained {
    @apply text-white bg-main-blue;

    &:hover {
      background: rgba(64, 78, 237, 0.9);
    }

    &:disabled {
      @apply text-gray-50 bg-button-disable cursor-not-allowed;
    }
  }

  &-outlined {
    @apply bg-transparent border border-main-blue text-main-blue;

    &:hover {
      @apply bg-main-white;
    }

    &:disabled {
      @apply bg-transparent border text-gray-500   cursor-not-allowed border-button-disable;
    }
  }

  &-text {
    @apply text-main-blue;

    background: none;

    &:hover {
      @apply bg-main-white;
    }

    &:disabled {
      @apply bg-transparent text-gray-500  cursor-not-allowed;
    }
  }
}

.btn-size {
  &-large {
    @apply h-10;
  }

  &-medium {
    @apply h-9;
  }

  &-small {
    @apply h-8;
  }
}

button {
  position: relative;
  overflow: hidden;
}

button:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.313rem;
  height: 0.313rem;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }
  20% {
    transform: scale(25, 25);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(40, 40);
  }
}

button:focus:not(:active)::after {
  animation: ripple 1s ease-out;
}

.cancel {
  &:hover {
    background-color: rgba(255, 255, 255, 0);
  }
}