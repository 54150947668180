.panel-min {
  background-color: #fff;
  top: 0px;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right-width: 1px;
  --tw-border-opacity: 1;
  // border-color: rgb(226 226 226 / var(--tw-border-opacity));
  border-color: rgb(226 226 226/var(--tw-border-opacity));
  width: 4.625rem;

  &-logo {
    text-align: center;
    width: 100%;
    height: 3.4rem;
    display: block;
    margin: 0px;
    border-radius: 9999px;
    width: 3.625rem;
  }

  &-menu {
    align-items: center;
    justify-content: space-around;
    border-radius: 1.125rem;
    padding-left: 0px;
    display: flex;
    margin-top: 5.5rem;

    &_campus {
      position: absolute;
      height: 3.5rem;
      width: 3.5rem;
      background: rgba(0, 171, 85, .1);
      bottom: 0.6rem;
    }

    &_profile {
      margin-bottom: 1.25rem;
      height: 3.5rem;
      width: 3.5rem;
      cursor: pointer;
      --tw-bg-opacity: 1;
      background-color: rgb(244 246 248 / var(--tw-bg-opacity));
    }

    &_avatar {
      display: inline-block;
      height: 2rem;
      width: 2rem;
      border-radius: 9999px;
    }

    &_avatar_campus {
      display: inline-block;
      border-radius: 9999px;
    }
  }
}

@media screen and (max-width: 1400px) {
  .panel-min {
    display: none;
  }
}