.accordion {
  @apply mb-6;

  &-header {
    @apply flex items-center w-full cursor-pointer select-none;

    &_btn {
      @apply relative mr-1 overflow-visible w-6 h-6;

      .icon {
        border-bottom: 3px solid;
        border-left: 3px solid;
        transform: rotate(225deg);
        transition: 0.2s linear;
        @apply inline-block w-3 h-3 border-main-blue;
      }

      &--open {
        .icon {
          @apply absolute;
          left: 19%;
          top: 12%;
          transform: rotate(315deg);
        }
      }
    }

    &_title {
      @apply font-bold text-base text-gray-600;
    }
  }
}
