.your-exam {
  @apply mt-1 2xl:flex-row flex-col shadow-schduled;
  padding: 2.5rem 3.25rem 4rem 3.125rem;
  display: flex;
  background: #fff;

  .date {
    display: flex;
    flex-direction: column;
    padding-right: 8.75rem;

    &_button {
      width: 20rem;
      height: 3rem;
      color: #2e72d2;
      border: 0.063rem solid #2e72d2;
      border-radius: 0.25rem;
      margin-top: 1rem;
    }

    &_scheduler {
      display: flex;
      align-items: center;
      padding-bottom: 0.813rem;

      &-text {
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 2;
        letter-spacing: 0;
        padding-right: 1rem;
      }

      &-image {
        width: 2.25rem;
        height: 2.25rem;
        border-radius: 5rem;
        background-color: #c4c4c4;
      }
    }

    &_details {
      padding: 1.25rem 4.563rem 2.594rem 2.5rem;
      background-color: #404eed;
      color: #f1f2f3;
      border-radius: 1rem;

      &-date {
        padding-bottom: 0.688rem;
      }

      &-date,
      &-time {
        .date_details-text {
          font-size: 1rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1.75rem;
          letter-spacing: 0;
        }

        .date_details-schedule {
          font-size: 1.5rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1.75rem;
          letter-spacing: 0;
          width: 13.375rem;
        }
      }
    }
  }

  .proctor {
    @apply mt-10 2xl:mt-0;

    &_text {
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.75rem;
      letter-spacing: 0;
      padding-bottom: 0.625rem;
      padding-left: 0.625rem;
    }

    &_search {
      display: flex;
      margin-bottom: 1.063rem;
      padding-top: 0.313rem;
      padding-bottom: 0.313rem;
      padding-left: 0.625rem;

      &-tool {
        padding-right: 0.906rem;
      }

      &-input {
        display: block;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem;
        letter-spacing: 0;
      }
    }

    &_button {
      width: 20rem;
      height: 3rem;
      color: #2e72d2;
      border: 0.063rem solid #2e72d2;
      border-radius: 0.25rem;
      margin-top: 1.25rem;
      margin-left: 1rem;
    }
  }
}

@media screen and (max-width: 1536px) {
  .your-exam {
    .date {
      // display: flex;
      // flex-direction: column;
      display: grid;
      place-items: center;
      padding-right: 0rem;
    }
  }
}