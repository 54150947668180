.prog-card {
  width: 15.5rem;
  /* height: 5.5rem; */
  border-radius: 1rem;
  margin-right: 1.25rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding-top: 3.4rem;
  padding-bottom: 3.4rem;

  &_shadow {
    box-shadow: 4px 10px 18px 4px rgba(34, 60, 80, 0.15);
  }

  &-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding-left: 20px; */
    position: relative;
    padding-top: 5px;

    &_opinion {
      height: 12px;
      position: relative;
      top: 6px;
      width: 4px;
      margin-right: 3px;

      &_1 {
        background: #f53031;
      }

      &_2 {
        background: #f2994a;
      }

      &_3 {
        background: #fbd036;
      }

      &_4 {
        background: #4eda7e;
      }

      &_none {
        background: #b2b2be;
      }
    }

    &_optn {
      margin-left: 50px;
      position: absolute;
      /* bottom: 5px; */

      top: 5px;
      right: 10px;
      cursor: pointer;
    }

    &_text {
      /* margin-right: 30px; */
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      bottom: 2px;

      h5 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        color: #919eab;
      }

      p {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        color: #212b36;
      }
      .flex span {
        padding-right: 10px;
      }
      .flex span.span-color {
        color: blue;
      }
    }
  }
}
