.notifications {
  &-table {
    @apply w-full;
    th {
      @apply text-left capitalize;
      padding: 0.75rem 0;
    }
    &_body {
      tr {
        &:nth-child(2n + 1) {
          background: rgba(196, 196, 196, 0.2);
        }
      }
      td {
        padding: 0.75rem 0;

        &:nth-child(4n + 1) {
          padding-left: 0.75rem;
        }
      }
    }
  }

  &-header {
    user-select: none;
    &_icons {
      display: flex;
      align-items: center;
      column-gap: 1.175rem;
      padding-left: 0.75rem;
    }
  }

  &-checkbox-list {
    @apply flex items-center;
    column-gap: 0.6875rem;
  }

  &-content {
    @apply align-top;
    width: 42% !important;
  }
  &-chips {
    width: 37% !important;
  }
}

.chips {
  @apply flex items-center;
  &-item {
    @apply inline-flex;
    padding: 0.15rem 0.25rem;
    column-gap: 0.25rem;
    .chip-label {
      @apply text-sm m-0 capitalize;
      text-overflow: ellipsis;
    }
    .chip-avatar,
    .chip-delete {
      @apply w-4 h-4 mr-0;
    }
    .chip-delete {
      font-weight: 800;
      font-size: 0.5rem;
      opacity: 0.7;
    }
    &--role {
      @apply bg-transparent text-gray-900;
      border: 1px solid rgba(145, 158, 171, 0.32);
    }
  }
}
