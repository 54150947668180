.pure-material-checkbox {
  z-index: 0;
  position: relative;
  display: inline-block;
  color: rgba(0, 0, 0, 0.87);
  font-family: var('Roboto', 'Segoe UI', system-ui, -apple-system);

  input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    z-index: -1;
    position: absolute;
    left: -0.625rem;
    top: -0.5rem;
    display: block;
    margin: 0;
    border-radius: 70%;
    width: 2.5rem;
    height: 2.5rem;
    background-color: #404eed;
    box-shadow: none;
    outline: none;
    opacity: 0;
    transform: scale(1);
    pointer-events: none;
    transition: opacity 0.3s, transform 0.2s;

    &:checked {
      background-color: #404eed;
    }

    &:indeterminate {
      background-color: #404eed;
    }
  }

  span {
    display: inline-block;
    width: 100%;
    cursor: pointer;

    &::before {
      content: '';
      display: inline-block;
      box-sizing: border-box;
      margin: 3px 11px 3px 1px;
      border: solid 2px; /* Safari */
      border-color: #404eed;
      border-radius: 4px;
      width: 18px;
      height: 18px;
      vertical-align: top;
      transition: border-color 0.2s, background-color 0.2s;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 3px;
      left: 1px;
      width: 10px;
      height: 5px;
      border: solid 2px transparent;
      border-right: none;
      border-top: none;
      transform: translate(4px, 5px) rotate(-45deg);
    }
  }
}

.pure-material-checkbox > input:checked + span::before,
.pure-material-checkbox > input:indeterminate + span::before {
  border-color: #404eed;
  background-color: #404eed;
}
.pure-material-checkbox > input:checked + span::after,
.pure-material-checkbox > input:indeterminate + span::after {
  border-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
}

.pure-material-checkbox:hover > input {
  opacity: 0.04;
}

.pure-material-checkbox:hover > input:focus {
  opacity: 0.16;
}

.pure-material-checkbox_non {
  z-index: 0;
  position: relative;
  display: inline-block;
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
  font-family: var(
    --pure-material-font,
    'Roboto',
    'Segoe UI',
    BlinkMacSystemFont,
    system-ui,
    -apple-system
  );

  input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    z-index: -1;
    position: absolute;
    left: -10px;
    top: -8px;
    display: block;
    margin: 0;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: #404eed;
    box-shadow: none;
    outline: none;
    opacity: 0;
    transform: scale(1);
    pointer-events: none;
    transition: opacity 0.3s, transform 0.2s;

    &:checked {
      background-color: #404eed;
    }

    &:indeterminate {
      background-color: #404eed;
    }
  }

  span {
    display: inline-block;
    width: 100%;
    cursor: not-allowed;

    &::before {
      content: '';
      display: inline-block;
      box-sizing: border-box;
      margin: 3px 11px 3px 1px;
      border: solid 2px; /* Safari */
      // border-color: #404eed;
      border-color: #e5e7eb;
      border-radius: 2px;
      width: 18px;
      height: 18px;
      vertical-align: top;
      transition: border-color 0.2s, background-color 0.2s;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 6px;
      left: 1px;
      width: 10px;
      height: 2px;
      border: solid 2px transparent;
      border-right: none;
      border-top: none;
      transform: translate(4px, 5px);
    }
  }
}

.pure-material-checkbox_non > input:checked + span::before,
.pure-material-checkbox_non > input:indeterminate + span::before {
  border-color: #919eab;
  background-color: #919eab;
}
.pure-material-checkbox_non > input:checked + span::after,
.pure-material-checkbox_non > input:indeterminate + span::after {
  border-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
}

.pure-material-checkbox_green {
  z-index: 0;
  position: relative;
  display: inline-block;
  color: rgba(0, 0, 0, 0.87);
  font-family: var('Roboto', 'Segoe UI', system-ui, -apple-system);

  input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    z-index: -1;
    position: absolute;
    left: -0.625rem;
    top: -0.5rem;
    display: block;
    margin: 0;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    background-color: #00a86b;
    box-shadow: none;
    outline: none;
    opacity: 0;
    transform: scale(1);
    pointer-events: none;
    transition: opacity 0.3s, transform 0.2s;

    &:checked {
      background-color: #00a86b;
    }

    &:indeterminate {
      background-color: #00a86b;
    }
  }

  span {
    display: inline-block;
    width: 100%;
    cursor: pointer;

    &::before {
      content: '';
      display: inline-block;
      box-sizing: border-box;
      margin: 3px 11px 3px 1px;
      border: solid 2px; /* Safari */
      border-color: #ababcd;
      border-radius: 2px;
      width: 18px;
      height: 18px;
      vertical-align: top;
      transition: border-color 0.2s, background-color 0.2s;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 3px;
      left: 1px;
      width: 10px;
      height: 5px;
      border: solid 2px transparent;
      border-right: none;
      border-top: none;
      transform: translate(4px, 5px) rotate(-45deg);
    }
  }
}

.pure-material-checkbox_green > input:checked + span::before,
.pure-material-checkbox_green > input:indeterminate + span::before {
  border-color: #00a86b;
  background-color: #00a86b;
}
.pure-material-checkbox_green > input:checked + span::after,
.pure-material-checkbox_green > input:indeterminate + span::after {
  border-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
}

.pure-material-checkbox_green:hover > input {
  opacity: 0.04;
}

.pure-material-checkbox_green:hover > input:focus {
  opacity: 0.16;
}
