#navbar-switch-campus-finance {
    .basic-panel-card {
        position: absolute; 
        width: 90%;
        background-color: rgba(0, 171, 85, 0.1);
        border-radius: 6px;
        display: flex;
        padding: 8px;
        align-items: center;
        height: 3.5rem;
        bottom: 0.5rem;
        .basic-panel-card-avatar {
            border-radius: 50%;
            aspect-ratio: 1 / 1;
            width: 2.5rem;
        }
        .basic-panel-menu_text {
            margin-left: 0.5rem;
            .basic-panel-menu_text-start {
                display: flex;
                align-items: center;
                text-align: left;
                font-size: 0.875rem;
                font-weight: 700;
                font-style: normal;
                line-height: 1rem;
                letter-spacing: 0em;
                --tw-text-opacity: 1;
                color: rgba(33, 43, 54, var(--tw-text-opacity));
                p {
                    width: 127px;
                    margin-right: 5%;
                }
            }
            .basic-panel-menu_text-end {
                display: flex;
                align-items: center;
                font-size: 0.875rem;
                line-height: 1.25rem;
                --tw-text-opacity: 1;
                color: rgba(99, 115, 129, var(--tw-text-opacity));
                p {
                    font-size: 0.75rem;
                    line-height: 1rem;
                    width: 120px;
                }
            }
            .basic-panel-card-triangle {
                display: flex;
                align-items: center;
                transition-duration: 200ms;
                transition-property: transform;
                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                cursor: pointer;
                width: 10%;
                height: 10px;
                margin-left: 0px;
            }
            .rotate-180 {
                transform: rotateZ('180');
            }
            p {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }

}