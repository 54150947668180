.enquiry-form-wrapper {
  width: 400px;
  margin: auto;
  padding: 24px;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;

  > h1 {
    font-size: x-large;
    font-weight: 800;
  }

  #f_form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    > div {
      display: grid;
      grid-template-columns: 40% 60%;
      height: 40px;
      align-items: center;

      > label {
        display: flex;
      }

      > select {
        border: 2px solid #c8c8c8;
        border-radius: 8px;
        height: 100%;
        padding: 8px;
      }

      > input {
        border: 2px solid #c8c8c8;
        border-radius: 8px;
        height: 100%;
        padding: 8px 10px;
      }

      &.captcha-wrapper {
        display: unset;
      }

      &.submit-button-wrapper {
        display: flex;
        flex-direction: row;
        height: 72px;
        >button {
          margin-top: 16px;
          width: 100%;
          height: 40px;
          background-color: #5959ff;
          color: white;
          font-size: large;
          font-weight: 600;
        };
      }
    }
  }

  > div.reset-button-wrapper {
    display: flex;
    flex-direction: row;
    height: 72px;
    width: 100%;
    >button {
      margin-top: 16px;
      width: 100%;
      height: 40px;
      background-color: #5959ff;
      color: white;
      font-size: large;
      font-weight: 600;
    };
  }
  
}