.toast {
  border-radius: 2px;
  padding: 1.5rem 1rem;
  min-width: 400px;
  max-width: 580px;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.08);
  // transition: all linear 0.3s;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;

  &-body {
    flex-grow: 1;
  }
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(calc(100% + 32px));
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}
@keyframes toast-in-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes toast-in-left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
