@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

#big-panel {
  .basic-panel-navbar-giiki-admin {
    padding-top: 0px;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: #fff;
    box-shadow: inset -1px 0px 0px rgba(145, 158, 171, 0.24);
    position: fixed;
    display: flex;
    height: 100vh;
    flex-direction: column;
    border-right-width: 1px;
    padding-left: 0.75rem;
    .basic-panel-logo-img {
      position: fixed;
      width: 57px;
      height: 22px;
      left: 41px;
      top: 32px;
    }
    .basic-panel-logo-admin {
      position: fixed;
      width: 49px;
      height: 22px;
      left: 103px;
      top: 35px;
    }
    .basic-panel-navbar-menu-admin {
      /* position: fixed; */
      /* top: 117px; */
      /* margin-top: 5rem; */

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .basic-panel-menu {
      margin-bottom: 0.75rem;
      display: flex;
      height: 3.5rem;
      min-height: 3.5rem;
      align-items: center;
      justify-content: space-evenly;
      border-radius: 1.125rem;
    }
    .basic-panel-menu_profile {
      cursor: pointer;
      --tw-bg-opacity: 1;
      background-color: rgba(244, 246, 248, var(--tw-bg-opacity));
      .basic-panel-menu_avatar {
        /* margin-right: 0.75rem; */
        /* margin-left: 0.25rem; */
        display: inline-block;
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 9999px;
      }
      .basic-panel-menu_text {
        user-select: none;
        white-space: nowrap;
        .basic-panel-menu_text-start {
          margin-right: 3rem;
          display: flex;
          align-items: center;
          text-align: left;
          font-size: 0.875rem;
          line-height: 1.25rem;
          font-weight: 700;
          font-style: normal;
          line-height: 1rem;
          letter-spacing: 0em;
          --tw-text-opacity: 1;
          color: rgba(33, 43, 54, var(--tw-text-opacity));
        }
        .basic-panel-menu_text-end {
          display: flex;
          align-items: center;
          font-size: 0.875rem;
          line-height: 1.25rem;
          --tw-text-opacity: 1;
          color: rgba(99, 115, 129, var(--tw-text-opacity));
        }
      }
    }
    .basic-panel-navbar_name {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 60px;
      color: #8c9094;
    }

    .basic-panel-navbar_link {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 1rem;
      letter-spacing: 0em;
      border-radius: 0.75rem;
      width: 96%;
      margin-bottom: 0.5rem;
      display: flex;
      align-items: center;
      text-align: left;
      height: 2.188rem;
      & img {
        margin-left: 0.5rem;
        margin-right: 0.8rem;
        width: 24px;
      }
    }

    .basic-panel-navbar_link:hover {
      --tw-bg-opacity: 1;
      background-color: rgba(244, 246, 248, var(--tw-bg-opacity));
      --tw-text-opacity: 1;
      color: rgba(33, 43, 54, var(--tw-text-opacity));
    }

    .basic-panel-navbar_link-active {
      --tw-text-opacity: 1;
      color: rgba(33, 43, 54, var(--tw-text-opacity));
      background: rgba(0, 171, 85, 0.1);
      & img {
        margin-left: 0.5rem;
        margin-right: 0.8rem;
      }
      .rotate_90 {
        transform: rotateZ(90deg);
      }
    }
    .basic-panel-navbar_actives {
      margin-bottom: 1.25rem;
      margin-left: 1.25rem;
      display: flex;
      flex-direction: column;
      border-left-width: 1px;
      border-color: #e1e3e5;
      .basic-panel-navbar_link_small {
        margin-bottom: 0.25rem;
        margin-left: 0.375rem;
        display: flex;
        align-items: center;
        border-radius: 0.625rem;
        padding-left: 0.5rem;
        width: 11.688rem;
        color: #b8bbbe;
        min-height: 2rem;
        height: fit-content;
        &:hover {
          --tw-bg-opacity: 1;
          background-color: rgb(244 246 248 / var(--tw-bg-opacity));
          --tw-text-opacity: 1;
          color: rgb(33 43 54 / var(--tw-text-opacity));
        }
      }
    }
    &-logo {
      @apply ml-4 h-12 rounded-full justify-between items-center flex mt-1.5 mb-3;

      &_img {
        @apply cursor-pointer;
      }
    }

    &-info {
      @apply w-36 text-white ml-1;

      p {
        @apply text-sm font-medium leading-4;
      }
    }

    &-menu {
      @apply mb-3 flex h-20 pl-12 justify-around items-center rounded-18px;

      width: 13.438rem;

      &_campus {
        background: rgba(0, 171, 85, 0.1);
      }

      &_profile {
        @apply bg-main-white cursor-pointer;
      }

      &_text {
        @apply select-none;

        white-space: nowrap;

        &-start {
          @apply text-sm text-main-black not-italic mr-12 font-bold leading-4 items-center tracking-normal flex text-left;

          h2 {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 8.4rem;
          }
        }

        &-end {
          @apply text-sm flex items-center text-second-gray;
        }
      }

      &_avatar {
        @apply inline-block ml-1 mr-3 h-10 w-10 rounded-full;
      }

      &_avatar_campus {
        @apply inline-block ml-1 mr-2 h-8 w-8 rounded-full;
      }

      &_triangle {
        @apply h-1.5 w-2.5 mr-2 relative bg-main-black;

        left: 0.313rem;
        clip-path: polygon(50% 100%, 0 0, 100% 0);
      }
    }
  }

  .open {
    width: 15rem;
    z-index: 50;
  }
  .close {
    width: 4.625rem;
    z-index: 0;
    opacity: 0;
  }

  @media screen and (max-width: 1400px) {
    .basic-panel {
      @apply h-screen flex flex-col pl-3 fixed bg-white z-10 w-60 border-r border-main-gray;
    }

    .basic-panel-closed {
      &_linear {
        @apply block;

        background: linear-gradient(
          75deg,
          rgba(22, 28, 36, 0.48) 0%,
          rgb(22, 28, 36) 100%
        );
      }
    }
  }
}
