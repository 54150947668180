#navbar-single-campus {
    .basic-panel-card {
        background-color: rgba(0, 171, 85, 0.1);
        border-radius: 6px;
        display: flex;
        padding: 0.35rem;
        align-items: center;
        width: 100%;
        height: 3.5rem;
        margin-bottom: 5px;
        position: static;
        cursor: pointer;
        .basic-panel-card-avatar {
            border-radius: 50%;
            aspect-ratio: 1 / 1;
            width: 2.5rem;
        }
        .basic-panel-menu_text {
            display: block;
            margin-left: 0.5rem;
            width: 135px;
            font-weight: 700;
            line-height: 1rem;
            .basic-panel-menu_text-start {
                text-align: left;
                font-style: normal;
                letter-spacing: 0em;
                --tw-text-opacity: 1;
                color: rgba(33, 43, 54, var(--tw-text-opacity));
                width: 120px;
                display: block;
                font-size: 0.875rem;
            }
            .basic-panel-menu_text-end {
                display: flex;
                align-items: center;
                font-size: 0.75rem;
                --tw-text-opacity: 1;
                color: rgba(99, 115, 129, var(--tw-text-opacity));
            }
            p {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }

}