.admission-overview-card {
  @apply ml-2 mb-5 rounded-2xl mt-1 flex items-center justify-between shadow-main-card;

  height: 9.688rem;
  overflow-x: auto;

  &-window {
    @apply rounded-2xl flex cursor-pointer flex-col items-center justify-center;

    width: 5.625rem;
    height: 5.875rem;
    background: rgba(240, 240, 240, 0.6);
  }

  &-group {
    @apply flex flex-col justify-between h-32 pl-6 pt-5 pb-5;
  }
}